.navbar .search_form_videos {
  justify-content: left;
}

.busca {
  width: 450px !important;
  text-transform: uppercase;
}

.suggest_search {
  position: absolute !important;
  width: 800px !important;
  background: #fff;
  top: 58px;
  left: 192px;
  box-shadow: 0 0 4px #999;
  border-radius: 10px;
  border-top-left-radius: 0;
}

.progress-bar {
  position: relative;
  height: 20px;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #333;
  background-color: #fff;
}

.filler {
  background: #1da598;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}
.progress-bar-animated{
    border-radius: .25rem;
    border: none;
    background-color: #1d4091;
    height: 1rem;
}