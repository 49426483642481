.video-grid a {
  background-color: black;
  color: #fff;
  display: flex;
  border-radius: 12px;
  transition: all 500ms ease-in-out;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 0;
}

.video-grid a:hover{
  border-radius: 0;
}

.video-grid a p {
  padding: 10px;
  position:  absolute;
  
  margin-top: 10px;
  margin-bottom: -5px;
  right: 0;
  bottom: 0;
  justify-content: flex-end;
  align-items: flex-start;
}

.video-grid h4 {
  color: #40475c;
  overflow: hidden;
  width: 100%;
  text-align:start;
  white-space: break-spaces;
}

.video-grid h4 strong{
  display: flex;
  width: 100%!important;
  white-space: break-spaces;
}
.video:hover .thumbVideo{
  width: 110%;
}

.thumbVideo {
  display: block;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}
