
.player-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  background:#333;
}
.btn-play-video{
  position: absolute;
  font-size: 15em;
  display: flex;
  align-self: center;
  color:rgba(255,255,255, .2);
  background: transparent;
  border:none;
  box-shadow: none;
  cursor: pointer;
}
.loading-video{
  position: absolute;
  font-size: 15em;
  align-self: center;
  color:rgba(255,255,255, .2);
  background: transparent;
  border:none;
  box-shadow: none;
}
.loading-video .percent{
  position: absolute;
    text-align: center;
    display: block;
    font-size: 0.15em;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -10px;
}
.btn.btn-play-video:active, .btn.btn-play-video:focus, .btn.btn-play-video:hover{
  background: transparent;
  color:rgba(0,0,0, 0.8);
  border:none;
  box-shadow: none;
  cursor: pointer;
}
.webcam_pip{
  display: none;
  position: fixed;
  z-index: 9999;
  right:10px;
  top:15px;
}
@media (max-width:490px) {
  .webcam_pip{
    display: block;
  }
  .webcam_pip video{
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
