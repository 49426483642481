.profile-page .page-header{
    background-size: cover;
}
.navbar-translate .navbar-brand{
    padding: 0;
}
.page-header .navbar-brand{
    position: absolute;
    bottom:-30px;
    left:50%;
    width: 180px;
    margin-left:-90px;
    background: rgb(216, 216, 216);
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    height: 180px;
    box-shadow: 0 0 2px #000;
    margin-right: 0;
    z-index: 10;
    padding: 20px;
}
.navbar-brand img{
    display: flex;
}
.navbar .navbar-nav .nav-item .nav-link{
    padding: 0.7rem;
}