.mainFooter{
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.mainFooter strong{
    color: #40475c;
    max-width: 90%;
}