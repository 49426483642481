.mainHeader {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
}

.mainHeader h3 {
    margin-top: unset;
    margin-bottom: unset;
    margin-right: 30px;
}

.mainHeader a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainVideos {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}