.td_progress {
    display: flex;
    justify-content: center;
    align-items: center;
}

.td_progress .progress {
    width: 100px;
    display: inline-flex;
    margin-left: 5px;
}

.td_progress .progress .progress-bar {
    height: 10px;
    border: none;
}

.indicadores {
    position: absolute;
    right: 0;
    top: 0;

}

.table thead th.bg-gray,
.bg-gray {
    background-color: #eee;
}

.indicadores .badge {
    font-size: 14px;
    border-radius: 0;
}

.indicadores .h3 {
    font-weight: bold;
}

.timeline {
    position: absolute;
    top: -25px;
    bottom: 0;
    left: 73px;
    color: #555;
    left: 65px;
    /*transition: all .25s;*/
}

.timeline:before {
    content: " ";
    display: block;
    background: #555;
    position: absolute;
    top: 8px;
    bottom: 32px;
    width: 2px;
    left: 0px;
}


.mainrow {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainDescription {
    width: 95%;
    max-width: 1600px !important;
}